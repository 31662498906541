<template>
  <div />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      selected: this.$store.getters['formMedication/idSelect'],
    }
  },

  mounted() {
    this.$store.commit('formMedication/UPDATE_LINK', 'favorite')
    this.edit()
  },

  methods: {
    edit() {
      this.$store.dispatch('formMedication/edit', { data: this.selected })
        .then(response => {
          this.formData(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formData(response) {
      const medication = response.data.data.template_schedules_info[0].medication_info
      const template = response.data.data.template_schedules_info[0].template_schedule_info

      this.$store.commit('formMedication/UPDATE_ID_FAVORITE', template.id)
      this.$store.commit('formMedication/UPDATE_MEDICATION', medication)

      if (template.schedule_times_type === 'FREE') {
        this.$store.commit('formMedication/UPDATE_TIME', 'Horários livres')
      } else if (template.schedule_times_type === 'FIXED') {
        let interval = ''
        switch (template.schedule_times.length) {
          case 1:
            interval = { id: 1, name: '1x ao dia (de 24 em 24 horas)', time: 0 }
            break
          case 2:
            interval = { id: 2, name: '2x ao dia (de 12 em 12 horas)', time: 1 }
            break
          case 3:
            interval = { id: 3, name: '3x ao dia (de 8 em 8 horas)', time: 2 }
            break
          case 4:
            interval = { id: 4, name: '4x ao dia (de 6 em 6 horas)', time: 3 }
            break
          case 6:
            interval = { id: 5, name: '6x ao dia (de 4 em 4 horas)', time: 5 }
            break
          case 12:
            interval = { id: 6, name: '12x ao dia (de 2 em 2 horas)', time: 11 }
            break
          case 24:
            interval = { id: 7, name: '24x ao dia (de 1 em 1 hora)', time: 23 }
            break
          default:
        }
        this.$store.commit('formMedication/UPDATE_TIME_INTERVAL', interval)
        this.$store.commit('formMedication/UPDATE_TIME', 'Intervalos fixos (8 em 8h, 6 em 6h…)')
      } else {
        this.$store.commit('formMedication/UPDATE_TIME', 'Não selecionar um horário agora')
      }

      if (template.schedule_times.length > 0) {
        let time = []
        let amount = []
        time = template.schedule_times.map(element => element.time)
        amount = template.schedule_times.map(element => element.dose)

        this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', [])
        this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', [])
        this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', [])
        this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', [])

        if (template.schedule_times_type === 'FREE') {
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', time)
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', amount)
        } else {
          this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', time)
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', amount)
        }
      }

      if (template.type === 'RECURRENT') {
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias contínuos (sem pausas)')
      } else if (template.type === 'ALTERNATED') {
        const days = [
          '2',
          '3',
          '5',
          '7',
          '15',
          '+',
        ]

        if (template.days_internal !== null) {
          days.map((element, index) => {
            if (template.days_internal.search(element) !== -1) {
              return 'primary'
            } if (index === 5) {
              return 'primary'
            }
            return 'outline-primary'
          })
        }

        this.$store.commit('formMedication/UPDATE_FREQUENCY_INTERVAL', template.days_internal)

        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias alternados (4 em 4 dias, 7 em 7…)')
      } else if (template.type === 'CUSTOM') {
        let arrayWeekColor = []

        const week = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ]

        arrayWeekColor = week.map(element => {
          if (template.schedule_week_days.toString().search(element) !== -1) {
            return 'primary'
          }
          return 'outline-primary'
        })

        const array = []
        arrayWeekColor.forEach((item, indice) => {
          if (item === 'primary') {
            switch (indice) {
              case 0:
                array.push('dom')
                break
              case 1:
                array.push('seg')
                break
              case 2:
                array.push('ter')
                break
              case 3:
                array.push('qua')
                break
              case 4:
                array.push('qui')
                break
              case 5:
                array.push('sex')
                break
              case 6:
                array.push('sab')
                break
              default:
            }
          }
        })

        const lengthArray = array.length
        let stringWeek = ''

        array.forEach((valueArray, index) => {
          stringWeek += valueArray
          if (index < lengthArray - 2) {
            stringWeek += ', '
          } else if (index === lengthArray - 2) {
            stringWeek += ' e '
          }
        })

        this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_COLOR', arrayWeekColor)
        this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_FORMATED', stringWeek)
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Dias específicos da semana')
      } else {
        this.$store.commit('formMedication/UPDATE_FREQUENCY', 'Não selecionar uma frequência agora')
      }

      if (template.duration_type === 'CONTINUOUS') {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Uso contínuo (sem data de término)')
      } else if (template.duration_type === 'INTERVAL') {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Quantidade de dias (início e fim definidos)')
      } else {
        this.$store.commit('formMedication/UPDATE_DURATION', 'Não selecionar uma duração agora')
      }

      this.$store.commit('formMedication/UPDATE_ACTION', 'Editar medicamento favorito')
      this.$router.push('favorite-medication')
    },
  },
}
</script>
